<template>
    <div class="form-section">
        <div class="form-block">
            <div class="currency-input select-block">
                <label for="from">Вы платите</label>

                <div class="form-select-block">
                    <input type="string" id="from" v-model="buyFrom.fromCurrency.value" @input="handleNumber">
                    <span v-if="buyFrom.fromCurrency.error" class="err-message">{{ buyFrom.fromCurrency.error }}</span>

                    <div class="form-select" @click="openCurrency">

                        <img src="../../assets/img/dollar.svg" alt="dollar" v-show="selectedCurrency === 'USD'">
                        <img src="../../assets/img/euro.svg" alt="euro" v-show="selectedCurrency === 'EUR'">
                        <img src="../../assets/img/som-currency.svg" alt="KGS" v-show="selectedCurrency === 'SOM'">
                        <span>{{ selectedCurrency }}</span>
                        <img src="../../assets/img/arrow-down.svg" alt="arrow">
                    </div>
                </div>


                <transition name="dropdown" mode="out-in">
                    <div v-show="isDropdownOpen" class="dropdown">
                        <p class="dropdown-label">Валюты</p>
                        <div class="dropdown-items">
                            <div v-for="item in store.getters.getAllCurrenciesBuy" :key="item?.id" class="dropdown-item"
                                @click="selectCurrency(item?.currency)">
                                <img src="../../assets/img/dollar.svg" alt="dollar" v-if="item.currency === 'USD'">
                                <img src="../../assets/img/euro.svg" alt="euro" v-else-if="item.currency === 'EUR'">
                                <img src="../../assets/img/som-currency.svg" alt="som"
                                    v-else-if="item.currency === 'SOM'">
                                <span>{{ item.currency }}</span>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>



            <div class="currency-input">
                <label for="receive">Вы получите</label>

                <div class='receive-block'>
                    <input type="number" id="receive" disabled v-model="buyFrom.toCurrency.value">

                    <div class="receive-block-currency">
                        <img src="../../assets/img/usdt.svg" alt="">
                        <span>USDT</span>
                    </div>
                </div>



            </div>

            <p class="bottom-message">Комиссия на покупку {{ store.getters.getUSDTData.commission }}%</p>
            <p class="bottom-message not-centered">Комиссия актуальна на: <strong>{{
                formatDate(store.getters.getUSDTData?.date) }}</strong>
            </p>


            <button class="form-button" @click="convert" :disabled="isDataLoading ? true : false">
                {{ isDataLoading ? 'Загрузка' : 'Купить' }}
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import api from '@/services/api'
import { useStore } from 'vuex';
import { createToast } from 'mosha-vue-toastify';

const selectedCurrency = ref('USD');
const isDropdownOpen = ref(false);
const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString("ru-RU");
    return formattedDate
}
const openCurrency = () => {
    isDropdownOpen.value = !isDropdownOpen.value;
};
const isDataLoading = ref(false)

const buyFrom = ref({
    fromCurrency: { value: null, error: '' },
    toCurrency: { value: null, error: '' }
});

const isValid = ref(false)
const handleNumber = () => {
    const value = buyFrom.value.fromCurrency.value;

    buyFrom.value.toCurrency.value = ''

    if (!/^\d+(\.\d+)?$/.test(value) || parseFloat(value) <= 0) {
        buyFrom.value.fromCurrency.error = "Введите число";
        isValid.valid = false
    } else {
        buyFrom.value.fromCurrency.error = '';
        isValid.value = true
    }
};


const store = useStore()
const currencies = [
    { name: "USD", value: 'usd' },
    { name: "EUR", value: 'euro' },
    { name: "KGS", value: 'som' }
];

const selectCurrency = (value) => {
    selectedCurrency.value = value;
    isDropdownOpen.value = false;
};


const convert = async () => {
    handleNumber()
    if (isValid.value) {
        try {
            isDataLoading.value = true
            const response = await api.post(`/Exchange/convert`, {

                "amount": buyFrom.value.fromCurrency.value,
                "fromCurrency": selectedCurrency.value,
                "toCurrency": "USDT",
                "isBuy": true

            })
            console.log('response conver ', response)
            if (response.data.code === 200) {
                buyFrom.value.toCurrency.value = response.data.message


            }

        } catch (err) {
            console.log('err', err)

            createToast("Произошла ошибка", {
                type: "danger",
                position: "top-right",
                showIcon: true,
            });
        } finally {
            isDataLoading.value = false
        }
    }

}
</script>

<style scoped>
.form-section {
    margin-top: 20px;
}

.select-block {
    position: relative;
    margin-bottom: 12px;
}

.currency-input {
    display: flex;
    flex-direction: column;

}

.form-select-block {
    width: 100%;
    position: relative;
}

.currency-input label {
    font-size: 14px;
    line-height: 18px;
    color: #828282;
}

.form-select {
    position: absolute;
    right: 35px;
    top: 25%;
    display: flex;
    gap: 6px;
    align-items: center;
    cursor: pointer;
}

.currency-input input {
    background: white;
    padding: 12px 16px;
    border-radius: 8px;
    outline: none;
    border: 1px solid #E0E0E0;
    width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.dropdown {
    background: white;
    width: 100%;
    border-radius: 8px;
    padding-bottom: 16px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
    margin: 0 auto;
}



.dropdown-label {
    color: rgba(130, 130, 130, 1);
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
    padding: 20px 0 0 12px;
}

.form-select:hover {
    cursor: pointer;
}

.dropdown-items {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.dropdown-item {
    display: flex;
    gap: 6px;
    align-items: center;
    padding: 10px;
    transition: background 0.22s ease;
}

.dropdown-item:hover {
    background: rgba(233, 236, 246, 1);
    cursor: pointer;
}


.dropdown-enter-active,
.dropdown-leave-active {
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out, scale 0.2s ease-in-out;
}

.dropdown-enter-from {
    opacity: 0;
    transform: translateY(-10px) scale(0.95);
}

.dropdown-leave-to {
    opacity: 0;
    transform: translateY(-10px) scale(0.95);
}


.receive-block {
    position: relative;
}

.receive-block-currency {
    position: absolute;
    top: 25%;
    right: 45px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.bottom-message {
    font-size: 14px;
    line-height: 18px;
    color: rgba(131, 131, 131, 1);
    margin: 20px 0 24px 0;
    text-align: center;
}

.not-centered {
    text-align: left;
}

.form-button {
    background: rgba(0, 71, 171, 1);
    border-radius: 8px;
    padding: 12px 20px;
    color: white;
    width: 100%;
    transition: .2s ease;
}

.form-button:hover {
    transition: .27s ease;
    opacity: .8;
}

#receive:hover {
    cursor: not-allowed;
}
</style>
