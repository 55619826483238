<template>

    <div class="wrapper">
        <h1 class="title">Подтвердите номер</h1>
        <p class="request__item-label">
            SMS с кодом отправлено на номер
            {{ phoneLocal }}
        </p>
        <div class="code-container">
            <input v-for="(digit, index) in code" :key="index" v-model="code[index]" ref="inputs" type="text"
                maxlength="1" @input="onInput(index)" @keydown.backspace="onBackspace(index)" @paste="onPaste"
                class="code-input" />

        </div>
        <span class="err-message" v-if="codeError">{{ codeError }}</span>
        <div class="next-kyc-btn first-block">
            <button class="blue-btn" @click='verifyCode' :disabled="isLoading">
                {{ isLoading ? 'Загрузка' : 'Далее' }}
            </button>
        </div>

    </div>

</template>


<script setup>
import { ref, nextTick, onMounted } from 'vue';

const code = ref(new Array(6).fill(''));
const inputs = ref([]);
const router = useRouter()
import http from '@/services/api'
import { useRouter } from 'vue-router';
const isLoading = ref(false)
const onInput = (index) => {
    const kycToken = localStorage.getItem('kycToken')
    const input = inputs.value[index];
    const value = input.value;

    if (!/^\d$/.test(value)) {
        input.value = '';
        return;
    }

    code.value[index] = value;

    if (index < code.value.length - 1) {
        nextTick(() => inputs.value[index + 1]?.focus());
    }

    if (code.value.every(digit => digit !== '')) {
        if (kycToken && kycToken !== undefined) {
            verifyCode(kycToken);
        }

    }
};


const onBackspace = (index) => {
    if (code.value[index] === '' && index > 0) {
        nextTick(() => inputs.value[index - 1]?.focus());
    }
};


const onPaste = (event) => {
    const pasteData = event.clipboardData.getData('text');
    const numbers = pasteData.match(/\d/g);

    if (numbers) {
        numbers.slice(0, 6).forEach((num, index) => {
            code.value[index] = num;
        });

        nextTick(() => inputs.value[numbers.length - 1]?.focus());
    }

    event.preventDefault();

};
const codeError = ref('')

const verifyCode = async (kycToken) => {
    const finalCode = code.value.join('');
    if (finalCode?.length === 6) {
        try {
            isLoading.value = true
            const response = await http.post(`/Kyc/check-verification-code?phone=${phoneLocal.value}&code=${finalCode}`, null, {
                headers: {
                    Authorization: `Bearer ${kycToken}`
                }
            })
            console.log('response chec code', response)
            if (response.data.code === 200) {
                router.push('/kyc-final')
            }


        } catch (err) {
            console.log(err)
            if (err.response.status === 404 || err.response.status === 456) {
                codeError.value = 'Неправильный код'

            }
        } finally {
            isLoading.value = false
        }

    }

}

const phoneLocal = ref()
onMounted(() => {

    const phoneFinal = localStorage.getItem('phoneFinal')
    if (phoneFinal && phoneFinal !== undefined) {
        const phoneNumber = localStorage.getItem('phoneNumber')
        if (phoneNumber && phoneNumber !== undefined) {
            phoneLocal.value = phoneNumber
        }
    } else {
        router.push('/kyc-phone')
    }

})


</script>



<style scoped>
h1 {
    margin-bottom: 12px;
}

.request__item-label {
    font-size: 16px;
    line-height: 24px;
    color: #838383;

}

.wrapper {
    padding: 20px;
}


.next-kyc-btn {
    margin-top: 214px;
}

label {
    font-size: 12px;
    line-height: 18px;
    color: #7F7D83;

}

.phone-block {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.phone-block input {
    width: 335px;
    border-radius: 10px;
    background: #fff;
    padding: 12px;
    color: #222222;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid #F5F7FC;
}

.code-container {
    display: flex;
    gap: 10px;
}

.code-input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

.code-input:focus {
    border-color: #0047ab;
}

@media (max-width:768px) {
    .phone-block input {
        width: 100%;
    }

    .request__item-label {
        width: 100%;
    }
}
</style>