import http from "@/services/api.js";
export const currencyModule = {
  state: {
    allCurrencies: [],
    usdtData:{}
  },

  actions: {
    async fetchCurrencies({state}) {
        try {
            const response = await http('/Exchange/get-current-rate')
            console.log('resposnse fetch currencies', response)
            if (response.data.code === 200) {
              state.allCurrencies = response.data.message
              state.usdtData = response.data.message?.find((item) => item.currency === 'USDT')
              console.log('usdtData',state.usdtData)
            }
        } catch (err) {
            console.log(err)
        }
    }

    // resetDate({ state, dispatch }) {
    //   state.query.fromDate = "";
    //   state.query.toDate = "";
    //   dispatch("fetchClients");
    // },
  },

  getters: {
    getAllCurrencies(state) {
      return state.allCurrencies;
      },
      getAllCurrenciesBuy(state) {
        return state.allCurrencies?.filter((item)=>item.currency !=='USDT')
    },
    getUSDTData(state) {
        return state.usdtData
      }
  
 
  },
};
