<template>
  <div class="wrapper">
    <h1 class="title">Введите номер телефона</h1>
    <p class="request__item-label">
      Введите номер телефона, который будет привязан к аккаунту
    </p>


    <div class="phone-block">

      <label for="phone">Номер телефона</label>
      <input type="text" id="phone" v-model='formattedPhone' @input="formatPhone">

      <span v-if="phoneError" class="err-message">{{ phoneError }}</span>
    </div>

    <div class="next-kyc-btn first-block">
      <button class="blue-btn" @click="nextRoute" :disabled="isLoading">
        {{ isLoading ? 'Загрузка...' : 'Далее' }}
      </button>
    </div>
  </div>





</template>


<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import http from "@/services/api.js";
const router = useRouter()
const phonePattern = "+996 ";

const formattedPhone = ref(phonePattern);

const phoneError = ref("");

const formatPhone = () => {
  let input = formattedPhone.value?.replace(/\D/g, "");
  input = input.slice(3, 12);

  if (input.length === 0) {
    phoneError.value = "Это поле обязательно";
  } else if (input.length > 0 && input.length < 9) {
    phoneError.value = "Введите действительный номер телефона";
  } else {
    phoneError.value = "";
  }

  let formatted = phonePattern;
  if (input.length > 0) formatted += input.slice(0, 3);
  if (input.length >= 4) formatted += " " + input.slice(3, 5);
  if (input.length >= 6) formatted += " " + input.slice(5, 7);
  if (input.length >= 8) formatted += " " + input.slice(7, 9);

  formattedPhone.value = formatted;
};
const isLoading = ref(false)
const sendCode = async (phone, kycToken) => {
  isLoading.value = true
  try {
    const response = await http.post(`/Kyc/send-verification-code?phone=${phone}`, null, {
      headers: {
        Authorization: `Bearer ${kycToken}`
      }
    })
    console.log('response send code', response)
    if (response.data.code === 200) {

      localStorage.setItem('phoneFinal', 'phoneFinal')
      router.push('/kyc-phone-check')

    }
  } catch (err) {
    console.log(err)
  } finally {
    isLoading.value = false
  }
}

const nextRoute = () => {
  const kycToken = localStorage.getItem('kycToken')
  if (formattedPhone.value.length === 0) {
    phoneError.value = "Это поле обязательно";
  } else if (formattedPhone.value.length > 0 && formattedPhone.value.length < 9) {
    phoneError.value = "Введите действительный номер телефона";
  } else if (formattedPhone.value.length === 17) {
    phoneError.value = "";

    const savedPhone = formattedPhone.value.replace(/\D/g, '').replace(/^996/, '996')
    localStorage.setItem('phoneNumber', savedPhone)
    if (kycToken && kycToken !== undefined) {
      sendCode(savedPhone, kycToken)
    }

  }
  if (!phoneError.value) {



  }
}

onMounted(() => {
  const phoneOpen = localStorage.getItem('phoneOpen')
  if (phoneOpen && phoneOpen !== undefined) {
    return;
  } else {
    router.push('/kyc')
  }
})

</script>



<style scoped>
h1 {
  margin-bottom: 12px;
}

.request__item-label {
  font-size: 16px;
  line-height: 24px;
  color: #838383;

}

.wrapper {
  padding: 20px;
}


.next-kyc-btn {
  margin-top: 214px;
}

label {
  font-size: 12px;
  line-height: 18px;
  color: #7F7D83;

}

.phone-block {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.phone-block input {
  width: 335px;
  border-radius: 10px;
  background: #fff;
  padding: 12px;
  color: #222222;
  font-size: 16px;

  line-height: 24px;
  border: 1px solid #F5F7FC;
}




@media (max-width:768px) {
  .phone-block input {
    width: 100%;
  }

  .request__item-label {
    width: 100%;
  }
}
</style>