import { createStore } from "vuex";
import { auth } from "./auth.module";
import { clientsModule } from "./clients";
import { p2tApp } from "./p2t-app";
import { currencyModule } from "./currencies";
const store = createStore({
  modules: {
    auth,
    clientsModule,
    p2tApp,
    currencyModule
  },
  state: {
    API_URI: "https://api.trustex.kg",
  },
});

export default store;
