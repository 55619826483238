import * as CryptoJS from 'crypto-js';

function useEncodeNumber(number) {
    const key=process.env.VUE_APP_HASH_KEY
    const iv = process.env.VUE_APP_HASH_IV
    const keyUtf8 = CryptoJS.enc.Utf8.parse(key);
    const ivUtf8 = CryptoJS.enc.Utf8.parse(iv);
    const encrypted = CryptoJS.AES.encrypt(number.toString(), keyUtf8, {
        iv: ivUtf8,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
}

export {useEncodeNumber}