<template>
    <div class="calculator">
        <div class="form">
            <div class="switch-container" :class="{ 'move-right': activeIndex === 2 }">
                <button @click="activeIndex = 1" class="switch-button">Купить</button>
                <button @click="activeIndex = 2" class="switch-button">Продать</button>
            </div>

            <Buy v-if="activeIndex === 1" />
            <Sell v-else-if="activeIndex === 2" />
        </div>
    </div>
</template>

<script setup>
import Buy from "./Buy.vue";
import { onMounted, ref } from "vue";
import Sell from "./Sell.vue";
import { useStore } from "vuex";

const activeIndex = ref(1);

const store = useStore()

onMounted(() => {
    store.dispatch('fetchCurrencies')
})
</script>

<style scoped>
.calculator {
    padding: 32px;
    border-radius: 16px;
    background: white;
    width: 550px;
}


.switch-container {
    display: flex;
    width: 100%;
    max-width: 550px;
    height: 48px;
    background: #E9ECF6;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    padding: 4px;
}


.switch-container::before {
    content: "";
    position: absolute;
    width: calc(50% - 4px);
    height: calc(100% - 8px);
    background: white;
    border-radius: 12px;
    transition: transform 0.3s ease-in-out;
    left: 4px;
}


.switch-container.move-right::before {
    transform: translateX(100%);
}


.switch-button {
    width: 50%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 8px 12px;
    position: relative;
    z-index: 1;
    transition: color 0.3s ease-in-out;
}


.switch-container.move-right .switch-button:nth-child(2),
.switch-container:not(.move-right) .switch-button:nth-child(1) {
    color: black;
}

@media (max-width:768px) {
    .calculator {
        width: 100%;
    }
}
</style>
